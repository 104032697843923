import React from 'react';
import { Text, Tooltip } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';

function LikertScale(props) {
  const ratingLabels = {
    english: ['Very Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'],
    german: ['Sehr unzufrieden', 'Unzufrieden', 'Neutral', 'Zufrieden', 'Sehr zufrieden'],
  };

  return (
      <div className="w-full px-3">
        <div className="grid grid-cols-6 gap-4 py-2 items-center">
          <div className="col-span-1 text-center"></div>
          {[5, 4, 3, 2, 1].map((rating) => (
              <div key={rating} className="col-span-1 text-center">
                <Text maxW="100%" isTruncated className="text-ellipsis">
                  {ratingLabels[props.language][rating - 1]}
                </Text>
              </div>
          ))}
        </div>
        {props.questions.map((question, index) => (
            <div key={index} className="grid grid-cols-6 py-2 justify-items-center items-center">
              <div className="col-span-1 inline-flex w-full justify-end items-center text-right pr-2">
                <Text maxW="100%" isTruncated className="text-ellipsis">
                {question.category[props.language]}
                </Text>
                <Tooltip
                    hasArrow
                    label={question.question[props.language]}
                    placement="right"
                    bg="#1a202c"
                    color="white"
                    fontSize="md"
                    fontWeight="normal"
                    borderRadius="md"
                    p={6}
                >
              <span className="text-xs">
                <FiInfo className="w-5 h-5 ml-2" size="xs" />
              </span>
                </Tooltip>
              </div>
              {[5, 4, 3, 2, 1].map((rating) => (
                  <div
                      key={rating}
                      className="col-span-1 border-1 h-16 w-12 md:w-32 flex cursor-pointer hover:bg-gray-100 justify-center items-center"
                      onClick={() => props.handleRatingChange(index, rating.toString())}
                      style={{
                        background: props.ratings[index] === rating.toString() ? '#e2e8f0' : 'white',
                      }}
                  >
                    <input
                        type="radio"
                        className="form-radio"
                        name={`question${index}`}
                        value={rating}
                        checked={props.ratings[index] === rating.toString()}
                        onChange={async () => await props.handleRatingChange(
                            index, rating.toString())}
                    />
                  </div>
              ))}
            </div>
        ))}
      </div>
  );
}

export default LikertScale;
