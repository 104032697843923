import React from "react";

function Header() {
  return (
      <header>
        <meta charSet="utf-8" />
        <title>MAKcloud</title>
      </header>

  );
}

export default Header;