import React from 'react';



export default function Folder() {
    return (
        <div>
            <h1>SharedFolder</h1>
        </div>
    );
}