import React from 'react';
import { makLogo } from '../data/images/';

export default function Navbar() {
  return (
      <nav 
      id='navbar'
      
      className="top-0 flex w-full flex-wrap items-center justify-between bg-neutral-200  py-2 text-neutral-500  hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 lg:py-4">
        <div className="flex w-full items-center justify-between px-3">
          <a className="flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 lg:mb-0 lg:mt-0">
            <img src={makLogo} alt="logo" className="w-12 h-12" />
            <span className="font-light text-black-800 text-2xl tracking-tight leading-10 ml-4">MAKcloud</span>
          </a>
        </div>
      </nav>
  );
}
