import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.css";

import "./VideoCarousel.css";


const VideoCarousel = () => {
  const videoProperties = [
    {
      id: 1,
      src: "https://www.youtube.com/watch?v=hK9npsn6O8s&ab_channel=LowReadyMedia",
    },
    {
      id: 2,
      src: "https://www.youtube.com/watch?v=ayrYfs6TcXM&ab_channel=MAKchannel",
    },
    {
      id: 3,
      src: "https://www.youtube.com/watch?v=3-l085sPDUY&ab_channel=WaffenlandTV",
    },
    {
      id: 4,
      src: "https://www.youtube.com/watch?v=yQqy9RtDkqA&ab_channel=MAKchannel",
    }
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const carouselRef = useRef(null);
  
  // check if width is desktop or mobile

  const isDesktop = window.innerWidth > 768;

  const handleSlide = (selectedIndex) => {
    setCurrentVideoIndex(selectedIndex);
  };

  useEffect(() => {
    setIsPlaying(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0); // Reset the video to the beginning
    }
  }, [currentVideoIndex]);

  // // hide element with id navbar and footer
  useEffect(() => {
    const navbar = document.getElementById("navbar");
    const footer = document.getElementById("footer");
    navbar.style.display = "none";
    footer.style.display = "none";
    return () => {
      navbar.style.display = "block";
      footer.style.display = "block";
    };
  }, []);

 

  return (
    
    <div className="App" >
      
      <Carousel
      // center slide
        style={{ margin: "auto" }}
        activeIndex={currentVideoIndex}
        onSelect={handleSlide}
        interval={isPlaying ? null : 3000}
        indicators={
          videoProperties.length > 1
        }
       onClick={() => {
          setIsPlaying(!isPlaying);
        }}
        ref={carouselRef}
        variant="dark"
        onSlide={(e) => {
          console.log(e);
        }}
      >
        {videoProperties.map((videoObj) => (
          <Carousel.Item key={videoObj.id}
          >
            <ReactPlayer
              style={{
                margin: "auto",
              }}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
              
              height={ isDesktop ? "360px" : "300px"}
              width={ isDesktop ? "640px" : "100%"}
              light={true}
              ref={playerRef}
              url={videoObj.src}
              pip={true}
              controls={true}
              playing={currentVideoIndex === videoObj.id - 1 && isPlaying}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onEnded={() => setIsPlaying(false)}
            />
 
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default VideoCarousel;
