import React from 'react';


import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  
} from 'react-google-recaptcha-v3';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import File from './pages/File';
import Folder from './pages/Folder';

import Navbar from './components/Navbar';
import FeedbackForm from './pages/FeedbackForm';

import { ChakraProvider } from '@chakra-ui/react'
import VideoCarousel from './components/video-carousel/VideoCarousel';



function App() {

  return (
      <GoogleReCaptchaProvider
          reCaptchaKey="6LfS9DAmAAAAALFHKfzyeeC9c8NmSsuwCOXUUy3f"
          language="de"
          useRecaptchaNet="true"
          useEnterprise="true"
          

          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
          }}
      >
      <ChakraProvider>

      <div className={"h-screen"}>
        <Header />
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/file/:id" element={<File />} />
            <Route path="/folder/:id" element={<Folder />} />
            <Route path="/survey/:id" element={<FeedbackForm />} />
            <Route path="/maktrigger" element={<VideoCarousel />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
 
      </ChakraProvider>
      </GoogleReCaptchaProvider>
  );
}

export default App;

/*
GraphQL endpoint: https://d3cm3hsvrnfldc6vaawybrssku.appsync-api.eu-central-1.amazonaws.com/graphql
GraphQL API KEY: da2-nturx4d3ojbjle3kdpeugrckde
 */
