import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import axios from 'axios';
import {Button, Icon, Loader} from '@aws-amplify/ui-react';
import {AiOutlineCloudDownload} from 'react-icons/ai';

export default function File() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const params = useParams();

  useEffect(() => {
     fetchData();
  }, []);


  const fetchData = async () => {
    try {
      axios.defaults.headers.common['X-API-KEY'] = 'hmZGxG08BBawC2weK8h824FVBUDfLS0j7RwSMunV';
      // preflight request
      await axios.options('https://oloxz2uyjd.execute-api.eu-central-1.amazonaws.com/Prod/app?id=' + params.id + '&type=FILE');
      axios.post('https://oloxz2uyjd.execute-api.eu-central-1.amazonaws.com/Prod/app?id=' + params.id + '&type=FILE', {
      }).then((response) => {
          console.log(response);
        if (response.status === 403) {
          setError(403);
        } else if (response.status !== 200) {
          console.log('Error:', response.status);
          setError(response.status);
        } else {
          setFile(response.data);
        }
        setLoading(false);
      });
    } catch (error) {
      setError(404);
      setLoading(false);
      console.error('Error:', error.message);
    }
  };


  const handleDownload = async () => {
    setDownloading(true);
    var link = document.createElement("a");
    link.href = file?.signedUrl;
    link.target = "_blank";
    link.setAttribute("download", file?.data.name || "");
    link.click();

    // after 3 seconds, set the downloading to false
    setTimeout(() => {
      setDownloading(false);
    }, 3000);
  };



  return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white rounded-lg shadow-lg p-8">
          {loading ? (
              <div className="flex items-center justify-center">
                <Loader type="ThreeDots" color="#6B7280" width={40} height={40} />
              </div>
          ) :  error ? (
              <div className="flex items-center justify-center">
                <Icon name="warning" />
                <span className="ml-2"> {error === 403 ? 'File has expired!' : 'File not found!'}</span>
              </div>
          ) : (

              <div>
                <h1 className="text-3xl font-semibold text-center mb-6">{file?.data.name}</h1>
                <div className="flex flex-col items-center">
              <span className="text-sm text-gray-500 mb-2">
                Expires in {Math.ceil((new Date(file?.data.expiresAt) - new Date().getTime()) / (24 * 60 * 60 * 1000))} days
              </span>
                  <span className="text-sm text-gray-500 mb-2">Size: {(file?.data.size / 1000000).toFixed(2)} MB</span>
                  <span className="text-sm text-gray-500 mb-6">Type: {file?.data.type}</span>
                  <Button
                      isLoading={downloading}
                      id="download"
                      variation='primary'
                      className="flex items-center px-4 py-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
                      onClick={  () => {
                        handleDownload().then(r => console.log(r));
                      }}
                  >
                    <AiOutlineCloudDownload className="w-5 h-5 mr-2" />
                    Download
                  </Button>


                </div>
              </div>
          )}
        </div>
      </div>
  );
}



