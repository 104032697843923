import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';




import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DE, US } from 'country-flag-icons/react/1x1';
import { Button, Divider, HStack, Input, InputGroup, InputLeftAddon, Textarea } from '@chakra-ui/react';
import LikertScale from '../components/form/LikertScale';
import { makLogo } from '../data/images';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

export default function FeedbackForm() {
  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const [ratings, setRatings] = useState([]);
  const [language, setLanguage] = useState('english');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  const params = useParams();

  const surveyTitle = language === 'english' ? 'Customer Satisfaction Survey' : 'Kundenzufriedenheitsumfrage';
  const namePlaceholder = language === 'english' ? 'Name (optional)' : 'Name (optional)';
  const emailPlaceholder = language === 'english' ? 'Email (optional)' : 'Email (optional)';
  const commentsPlaceholder = language === 'english' ? 'Comments (optional)' : 'Kommentare (optional)';
  const submitButton = language === 'english' ? 'Submit' : 'Einreichen';
  const thankYouTitle = language === 'english' ? 'Thank you!' : 'Vielen Dank!';
  const visitWebsiteButton = language === 'english' ? 'Visit mak.ag' : 'Besuchen Sie mak.ag';


  const notifyMissingFields = (message) => {
    toast.error(message || (language === 'english' ? 'Please answer all questions!' : 'Bitte beantworten Sie alle Fragen!'));
  };

  const notifySuccess = () => {
    const message = language === 'english' ? 'Thank you for your feedback!' : 'Vielen Dank für Ihr Feedback!';
    toast.success(message);
  };

  useEffect(() => {
    const allQuestionsAnswered = ratings.every((rating) => typeof rating === 'string' && rating !== '');
    setAllQuestionsAnswered(allQuestionsAnswered);
  }, [ratings]);

  useEffect(() => {
    setRatings(Array.from({ length: questions.length }, () => ''));
  }, [questions]);

  const handleRatingChange = (index, value) => {
    const tempRatings = [...ratings];
    if (value !== '') {
      tempRatings[index] = value;
    }

    setRatings(tempRatings);
  };

  const handleLanguageChange = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'english' ? 'german' : 'english'));
  };


  const onSubmit = async (data) => {
     // there is GoogleReCaptchaProvider on top of app. Get token from there


    if (!captchaToken) {
      const errorMessage = language === 'english' ? 'Please complete the reCAPTCHA!' : 'Bitte vervollständigen Sie die reCAPTCHA!';
      notifyMissingFields(errorMessage);
      return;
    }

    setIsSubmitting(true);
    // check if any questions are unanswered
    let allAnswered = true;
    ratings.forEach((rating, index) => {
      if (rating === '') {
        allAnswered = false;
      }
    });
    if (!allAnswered) {
      notifyMissingFields();
      setIsSubmitting(false);
      return;
    }


    if (ratings.length !== questions.length) {
      notifyMissingFields();
      setIsSubmitting(false);
      return;
    }

    if (!captchaToken) {
      const errorMessage = language === 'english' ? 'Please complete the reCAPTCHA!' : 'Bitte vervollständigen Sie die reCAPTCHA!';
      notifyMissingFields(errorMessage);
      setIsSubmitting(false);
      return;
    }


    try {
      axios.defaults.headers.common['X-API-KEY'] = 'hmZGxG08BBawC2weK8h824FVBUDfLS0j7RwSMunV';
      await axios.options('https://oloxz2uyjd.execute-api.eu-central-1.amazonaws.com/Prod/app');
      /*
      make response in form of AWSJSON
       */
        const awsJsonResponses   =  questions.map((question, index) => {
            return {
                question: question,
                answer: ratings[index]
            }

        });
      const response = await axios.post('https://oloxz2uyjd.execute-api.eu-central-1.amazonaws.com/Prod/app?id=' + params.id + '&type=SURVEY', {
          name: getValues('name'),
          email: getValues('email'),
          comment: getValues('comments'),
          responses: awsJsonResponses,
          surveyId: params.id,
      });

      if (response.status === 403) {
        const errorMessage = language === 'english' ? 'File has expired!' : 'Datei ist abgelaufen!';
        notifyMissingFields(errorMessage);
      } else if (response.status !== 200) {
        console.log('Error:', response.status);
        const errorMessage = language === 'english' ? 'Error!' : 'Fehler!';
        notifyMissingFields(errorMessage);
      } else {
        console.log('Success:', response.status);
        setIsSubmitted(true);
        notifySuccess();
      }
    } catch (error) {
      console.error('Error:', error.message);
      return false;
    }
    setIsSubmitting(false);
  };



  return (
      <div className="container mx-auto py-8 mb-32  ">
        {!isSubmitted && (
            <>
              <img src={makLogo} alt="makLogo" className="w-20 h-20 mx-auto mb-4" />
              <h1 className="text-3xl text-center font-light bg-gray-100 py-4" style={{ fontFamily: 'Calibri' }}>
                {surveyTitle}
              </h1>
              <div className="flex justify-end mb-2">
                <button className="text-black text-sm px-4 py-2 rounded mr-2" onClick={handleLanguageChange}>
                  <div className="flex items-center">
                    {language === 'english' ? <DE title="Deutsch" className="w-5 h-5 mr-2" /> : <US title="English" className="w-5 h-5 mr-2" />}
                    {language === 'english' ? 'Deutsch' : 'English'}
                  </div>
                </button>
              </div>
            </>
        )}
        {!isSubmitted ? (
            <form onSubmit={handleSubmit(onSubmit)} className="max-w-6xl mx-auto">
              <div className="my-4 mx-auto">
                <HStack spacing={4}>
                  <InputGroup>
                    <InputLeftAddon children={namePlaceholder} />
                    <Input type="text" {...register('name')} />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon children={emailPlaceholder} />
                    <Input type="email" {...register('email')} />
                  </InputGroup>
                </HStack>
              </div>
              <Divider className="my-6" />
              <LikertScale questions={questions} handleRatingChange={handleRatingChange} language={language} ratings={ratings} />
              <Divider className="my-6" />
              <div className="my-4">
                <Textarea placeholder={commentsPlaceholder} {...register('comments')} />
              </div>
              <Divider className="my-6" />
              <div className="flex items-center mb-6 justify-end">
                <div className={"flex flex-col items-end"}>
                  <Button
                      type="submit"
                      size="lg"
                      isLoading={isSubmitting}
                      colorScheme={captchaToken && allQuestionsAnswered ? 'green' : 'gray'}
                      disabled={!captchaToken || !allQuestionsAnswered  }

                  >
                    {submitButton}
                  </Button>
                  <div className="  mt-4">
                    <label htmlFor="privacyPolicyCheckbox">
                      {language === 'english'
                          ? ('By submitting this form you agree to our ')
                          : 'Mit dem Absenden dieses Formulars stimmen Sie unserer '}
                    </label>

                    <a href="https://mak.ag/datenschutz" target="_blank" rel="noreferrer" className="text-blue-500">

                      {language === 'english' ? 'privacy policy' : 'Datenschutzerklärung'}

                    </a>

                  </div>
                </div>
              </div>
            </form>
        ) : (
            <div className="flex flex-col items-center">
              <h1 className="text-3xl font-bold mb-2">{thankYouTitle}</h1>
              <p className="mb-2">
                {language === 'english'
                    ? 'Your feedback has been submitted successfully.'
                    : 'Ihr Feedback wurde erfolgreich übermittelt.'}
              </p>
              <div className="flex justify-center mt-4">
                <Button colorScheme="blue" onClick={() => (window.location.href = 'https://mak.ag')}>
                  {visitWebsiteButton}
                </Button>
              </div>
            </div>
        )}
        <ToastContainer />
        <GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
      </div>

  );
}


const questions = [
  {
    category: {
      english: 'Quality',
      german: 'Qualität',
    },
    question: {
      english: 'Were you satisfied with the quality of our products?',
      german: 'Waren Sie mit der Qualität unserer Produkte zufrieden?',
    },
  },
  {
    category: {
      english: 'Delivery',
      german: 'Lieferung',
    },
    question: {
      english: 'Was the delivery complete?',
      german: 'War die Lieferung vollständig?',
    },
  },
  {
    category: {
      english: 'Advice',
      german: 'Beratung',
    },
    question: {
      english: 'Were you satisfied with the quality of our advice?',
      german: 'Waren Sie mit der Qualität unserer Beratung zufrieden?',
    },
  },
  {
    category: {
      english: 'Deadlines',
      german: 'Termine',
    },
    question: {
      english: 'Were you satisfied with the adherence to the promised dates?',
      german: 'Waren Sie mit der Einhaltung der zugesagten Termine zufrieden?',
    },
  },
  {
    category: {
      english: 'Service',
      german: 'Service',
    },
    question: {
      english: 'Were you satisfied with our service?',
      german: 'Waren Sie mit unserem Service zufrieden?',
    },
  },
  {
    category: {
      english: 'Developments',
      german: 'Entwicklungen',
    },
    question: {
      english: 'Were you satisfied with our service developments?',
      german: 'Waren Sie mit unseren Entwicklungs-Dienstleistungen zufrieden?',
    },
  },
  {
    category: {
      english: 'Complaints',
      german: 'Reklamation',
    },
    question: {
      english: 'Were you satisfied with our complaint handling?',
      german: 'Waren Sie mit unserer Reklamationsbearbeitung zufrieden?',
    },
  },
  {
    category: {
      english: 'Customer loyalty',
      german: 'Kundentreue',
    },
    question: {
      english: 'Would you recommend us to a good customer of yours?',
      german: 'Würden Sie uns einem guten Kunden von Ihnen weiterempfehlen?',
    },
  },
];