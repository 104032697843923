import React from 'react';

const Footer = () => {
  return (
      <footer
       id='footer'
      className="bg-neutral-200 text-center dark:bg-neutral-700 lg:text-left">
        <div className="p-4 text-center text-neutral-700 dark:text-neutral-200">
          This website is developed for Kilic Feintechnik GmbH by{' '}
          <a className="text-neutral-800 dark:text-neutral-400" href="https://www.mak-cet.com/">
            MAK CET
          </a>{' '}
          | Visit our website at{' '}
          <a className="text-neutral-800 dark:text-neutral-400" href="https://www.mak.ag/">
            www.mak.ag
          </a>
          .
        </div>
        <div className="p-4 text-center text-neutral-700 dark:text-neutral-200">
          &copy; 2023{' '}
          <a className="text-neutral-800 dark:text-neutral-400"
             target="_blank" rel="noreferrer"

             href="https://mak.ag/">
            Kilic Feintechnik GmbH
          </a>
        </div>
        <div className="p-4 text-center text-neutral-700 dark:text-neutral-200">
          <a className="text-neutral-800 dark:text-neutral-400"

             target="_blank" rel="noreferrer"
             href="https://www.mak.ag/datenschutz/">
            Datenschutz
          </a>{' '}
          |{' '}
          <a className="text-neutral-800 dark:text-neutral-400"

             target="_blank" rel="noreferrer"

             href="https://www.mak.ag/impressum/">
            Impressum
          </a>
        </div>
      </footer>
  );
};

export default Footer;
